// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/codebuild/output/src193294645/src/gatsby-auth-starter-aws-amplify/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("/codebuild/output/src193294645/src/gatsby-auth-starter-aws-amplify/src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src193294645/src/gatsby-auth-starter-aws-amplify/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logged-in-page-1-js": () => import("/codebuild/output/src193294645/src/gatsby-auth-starter-aws-amplify/src/pages/logged_in_page1.js" /* webpackChunkName: "component---src-pages-logged-in-page-1-js" */),
  "component---src-pages-sign-out-js": () => import("/codebuild/output/src193294645/src/gatsby-auth-starter-aws-amplify/src/pages/sign_out.js" /* webpackChunkName: "component---src-pages-sign-out-js" */)
}

